/**
 * Login Page
 */

// Core imports
import React from 'react';
import PropTypes from 'prop-types';

// Style, SEO and settings
import { styled, ThemeProvider, withTheme } from '@smooth-ui/core-sc';

// Additional Components/Containers
import LoadAsync from '_platform/src/utils/LoadAsync';
import PublicComponent from '_platform/src/utils/PublicComponent';

const Header = LoadAsync(() =>
  import(/* webpackChunkName: "header" */ 'components/Header/Header')
);
const LoginProvider = LoadAsync(() =>
  import(
    /* webpackChunkName: "loginProvider" */ '_platform/src/containers/LoginProvider/LoginProvider'
  )
);
const LoginForm = LoadAsync(() =>
  import(
    /* webpackChunkName: "loginForm" */ '_platform/src/components/LoginForm/LoginForm'
  )
);

const PageContainer = styled.div`
  align-items: center;
  display: flex;
  flex: 1 0 auto;
  justify-content: center;
`;

const LoginContainer = styled.div`
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0.25rem 2rem;
  max-width: 600px;
  min-width: 300px;
  padding: 1rem;
  width: 50%;
`;

const LoginPage = ({ theme }) => (
  <PublicComponent redirectOnError="/">
    <PageContainer>
      <ThemeProvider
        theme={{
          customHeaderBackground: 'transparent',
          // buttonBlockHasSeparator: true,
        }}
      >
        <LoginContainer>
          <Header
            verticalStack
            siteName={theme.settingsApp.siteName}
            siteLogo={theme.settingsApp.siteLogo}
            siteLogoHeight={theme.settingsApp.siteLoginLogoHeight}
            siteLogoMaxHeight={theme.settingsApp.siteLoginLogoMaxHeight}
          />

          <LoginProvider disableMeta>
            <LoginForm formLabels={{ labelUserName: 'Username' }} />
          </LoginProvider>
        </LoginContainer>
      </ThemeProvider>
    </PageContainer>
  </PublicComponent>
);

LoginPage.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default withTheme(LoginPage);
